import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { alertActions, playlistActions } from "_actions";
import {
  AddedBadge,
  CircleIcon,
  CreationForms,
  DefaultRowContainer,
  Loading,
  PreviouslyRemovedBadge,
  RemovedBadge,
  SmallRow,
  theme
} from "_styles";
import { getPlaylist, getPlaylistChanges, isPlaylistReady, SONG_STATE_ADDED } from "_reducers/playlist.reducer";
import { getPlaylistStats } from "../_reducers/playlist.reducer";
import { ReactTooltipStyled, SortTable } from '_components/Standard';

const Gutter = 46;

const ScrollingContainer = styled.div`
    overflow-y: auto;
    max-height: 400px;
    height: 100%;
`;

const InfoRow = styled(DefaultRowContainer)`
    justify-content: space-between;
    color: grey;
`;

const RevertActionLink = styled.div`
    color: #EEE;
    border-bottom: 1px solid transparent;
    cursor: pointer;

    &:hover {
        color: white;
        border-bottom: 1px solid white;
    }
`;

const Label = styled(DefaultRowContainer)`
    color: white;
    font-size: 24px;
`;

const IconContainer = styled.div`
    display: flex;
    flex: 0 0 ${Gutter}px;
    justify-content: center;
    align-items: center;
`;

const explicitTypeSongsInfo = (playlist, changeList) => {
  if (!playlist || !playlist.allowedExplicitType || playlist.allowedExplicitType.toLowerCase() === 'explicit') {
    return null;
  }
  const playlistExplicitType = playlist.allowedExplicitType.toLowerCase();
  const explicitSongs = changeList.filter(songChange => songChange.state === SONG_STATE_ADDED && songChange.songExplicitType === 'EXPLICIT');
  const adultSongs = changeList.filter(songChange => songChange.state === SONG_STATE_ADDED && songChange.songExplicitType === 'ADULT');
  let detectedExplicitType = '';
  if (playlistExplicitType === 'clean') {
    detectedExplicitType = explicitSongs.length > 0 ? 'Explicit'
        : adultSongs.length > 0 ? 'Adult' : '';
    if (detectedExplicitType === '') {
      return null;
    }
  } else if (playlistExplicitType === 'adult') {
    detectedExplicitType = explicitSongs.length > 0 ? 'Explicit' : '';
    if (detectedExplicitType === '') {
      return null;
    }
  }
  return {
    explicitSongs,
    adultSongs,
    playlistExplicitType,
    detectedExplicitType
  }
}

class ModalVerifyPlaylistChanges extends React.Component {
  state = {
    submitting: false,
    error: ''
  }

  componentDidMount() {
    if (!this.props.playlist) {
      playlistActions.getPlaylist(this.props.dispatch, this.props.id);
    }
    this.props.dispatch(playlistActions.getHistory(this.props.id));
  }

  revert = async () => {
    try {
      await playlistActions.revert(this.props.dispatch, this.props.id);
      alertActions.notificationSuccess(this.props.dispatch, "Playlist Reverted", this.props.playlist.name);
      this.props.onClose(true);
    } catch (e) {
      this.setState({error: e.toString()});
    }
  }

  publish = async () => {
    this.setState({error: '', submitting: true});
    try {
      const playlist = await playlistActions.publish(this.props.dispatch, this.props.id);
      alertActions.notificationSuccess(this.props.dispatch, "Playlist Published", this.props.playlist.name);
      this.props.onClose(true);
    } catch (e) {
      this.setState({error: e.toString()});
    } finally {
      this.setState({submitting: false});
    }
  }

  handleAddSong = song => {
    playlistActions.addSongs(this.props.dispatch, this.props.id, [song.id]);
  };

  handleDeleteSong = song => {
    playlistActions.deleteSongs(this.props.dispatch, this.props.id, [song.id]);
  };


  showExplicitWarningOrPublish() {
    let showWarning = false;
    let explicitSongsInfo = explicitTypeSongsInfo(this.props.playlist, this.props.songChangeList);
    if (explicitSongsInfo) {
      this.setState({showExplicitWarning: true});
      showWarning = true;
    }
    for (const change of this.props.songChangeList) {
      if (change.state === SONG_STATE_ADDED) {
        if (this.props.playlistHistory.find(h => h.song.id === change.id && h.changeAction === 'REMOVE')) {
          this.setState({showReaddedWarning: true});
          showWarning = true;
        }
      }
    }
    if (!showWarning) {
      this.publish();
    }
  }

  render() {
    const explicitSongsInfo = explicitTypeSongsInfo(this.props.playlist, this.props.songChangeList);
    const playlistHistory = this.props.playlistHistory;
    const rows = this.props.songChangeList.map(change => {
      const foundReaddedChange = playlistHistory.find(h => h.song.id === change.id && h.changeAction === 'REMOVE');
      const readded = foundReaddedChange ? 0 : change.state === SONG_STATE_ADDED ? 1 : 2;
      return {
        ...change,
        readded: readded
      };
    });
    const explicitReaddedTitle = (this.state.showExplicitWarning && explicitSongsInfo) ? explicitSongsInfo.detectedExplicitType + " tracks detected" :
        (this.state.showReaddedWarning ? "Readded tracks detected" : "");

    return (
        <ThemeProvider theme={theme}>
                <CreationForms
                    handleBackButton={this.props.onClose}
                    handleSubmit={this.showExplicitWarningOrPublish.bind(this)}
                    submitting={this.state.submitting}
                    alert={this.state.error}
                    title={`Review Pending Changes`}
                >
                    {this.props.ready ? (
                        <>
                            <Label>{this.props.playlist.name}</Label>
                            <InfoRow>
                                <div>{this.props.changeStats.added} added {this.props.changeStats.removed} removed</div>
                                <RevertActionLink onClick={this.revert}>Revert Changes</RevertActionLink>
                            </InfoRow>
                            <ScrollingContainer>
                                <SortTable
                                    rows={rows}
                                    defaultSortFiled={'readded'}
                                    columns={[
                                      {
                                        fixed: Gutter, rowRenderer: row => (
                                            <IconContainer>
                                                    {row.state === SONG_STATE_ADDED ? (
                                                        <CircleIcon
                                                            icon={['fal', 'check']}
                                                            onClick={e => this.handleDeleteSong(row)}
                                                        />) : (
                                                        <div style={{cursor: "pointer", fontSize: "20px"}}
                                                            onClick={e => this.handleAddSong(row)}
                                                        >+</div>
                                                    )}
                                                </IconContainer>
                                        )
                                      }, {
                                        percent: 1, title: 'title', field: 'title', rowRenderer: row => (
                                            <>
                                                    <SmallRow name={row.name} title={row.title} album={row.album}
                                                        artist={row.artist} />

                                                </>
                                        )
                                      }, {
                                        fixed: 100,
                                        title: 'status',
                                        field: 'readded',
                                        isNumber: true,
                                        rowRenderer: row => {
                                          if (row.state === SONG_STATE_ADDED) {
                                            let foundReaddedChange = playlistHistory.find(h => h.song.id === row.id && h.changeAction === 'REMOVE');
                                            if (foundReaddedChange) {
                                              const userName = foundReaddedChange.user.firstName + " " + foundReaddedChange.user.lastName;
                                              const formattedDate = new Date(foundReaddedChange.datePublished).toLocaleDateString();
                                              return <>
                                              <PreviouslyRemovedBadge data-tip data-for={"readded" + row.id} />
                                              <ReactTooltipStyled effect='solid' place="bottom" type='light'
                                                  id={"readded" + row.id}>
                                                     This song was deleted by {userName} on {formattedDate}
                                              </ReactTooltipStyled>
                                            </>
                                            }
                                            return <AddedBadge />
                                          } else {
                                            return <RemovedBadge />
                                          }
                                        }
                                      }
                                    ]}
                                />
                            </ScrollingContainer>
                        </>
                    ) : (
                        <Loading />
                    )}
                </CreationForms>
          {(this.state.showExplicitWarning || this.state.showReaddedWarning) && <CreationForms
              title={explicitReaddedTitle}
              handleBackButton={() => this.setState({showExplicitWarning: false, showReaddedWarning: false})}
              handleSubmit={this.publish}
              handleAccept={this.publish}
              handleClose={() => this.setState({showExplicitWarning: false, showReaddedWarning: false})}
              level={10002}>
                    <InfoRow>{explicitSongsInfo && "Your playlist is set to allow " + explicitSongsInfo.playlistExplicitType + " tracks only."}
                      <br />
                      <br />
                      {this.state.showReaddedWarning && "\nYour playlist contains tracks that were previously deleted."}
                      <br />
                      <br />Are you sure you want to publish your changes?</InfoRow>
                </CreationForms>}
            </ThemeProvider>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ready: isPlaylistReady(state, ownProps.id),
  playlist: getPlaylist(state, ownProps.id),
  songChangeList: getPlaylistChanges(state, ownProps.id),
  changeStats: getPlaylistStats(state, ownProps.id),
  playlistHistory: state.playlists ? state.playlists.playlistHistory.list : null,
});
const _ModalVerifyPlaylistChanges = withRouter(connect(mapStateToProps)(ModalVerifyPlaylistChanges));
export {
  _ModalVerifyPlaylistChanges
      as
          ModalVerifyPlaylistChanges
};
